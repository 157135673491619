import * as React from "react";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import background from "../../images/services/securite-biens.jpg";
import "../../scss/style.scss";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServicesListBasic from "../../components/services/ServicesListBasic";
import ContactUs from "../../components/buttons/ContactUs";

const SecuriteBien = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Services -
        Sécurité des biens
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <Banner
          category="Services"
          title="Sécurité des biens"
          content=""
          background={background}
          position="left"
        />
        <div className="container pt-4 pt-md-10 pb-4 pb-md-10">
          <div className="row justify-content-start">
            <div className="col-md-8">
              <div className="service service-single">
                <div className="content">
                  <h1>Services de sécurité pour la protection de vos biens</h1>
                  <p>
                    Notre société de sécurité offre des services de sécurité
                    pour la protection de vos biens. Nous offrons des{" "}
                    <em>solutions de sécurité personnalisées</em> pour répondre
                    aux besoins spécifiques de nos clients. Nous croyons que la
                    qualité de nos services de sécurité est la clé pour{" "}
                    <em>garantir la sécurité de vos biens.</em>
                  </p>
                  <h2>Surveillance et patrouille</h2>
                  <p>
                    Nous fournissons des services de surveillance et de
                    patrouille pour assurer la sécurité de vos biens en tout
                    temps.{" "}
                    <em>
                      Nos agents de sécurité patrouillent régulièrement vos
                      locaux
                    </em>{" "}
                    pour détecter tout signe d'intrusion ou d'activité suspecte.
                    Nous avons également des solutions de surveillance
                    électronique pour garantir une surveillance constante de vos
                    biens.
                  </p>
                  <h2>Contrôle d'accès</h2>
                  <p>
                    Nous offrons des <em>solutions de contrôle d'accès</em> pour
                    garantir que seules les personnes autorisées ont accès à vos
                    biens. Nous pouvons mettre en place des systèmes de contrôle
                    d'accès tels que des cartes d'identification, des codes
                    d'accès et des serrures électroniques pour garantir que
                    seules les personnes autorisées ont accès à vos biens.
                  </p>
                  <h2>Interventions d'urgence</h2>
                  <p>
                    Nous avons une équipe d'agents de sécurité formés pour faire
                    face à toutes les situations d'urgence possibles et pour
                    agir rapidement et efficacement en cas de besoin. En cas de
                    situation d'urgence, nous agissons immédiatement pour
                    minimiser les dommages et{" "}
                    <em>garantir la sécurité de vos propriétés.</em>
                  </p>
                  <p>
                    Nous sommes fiers de dire que nous offrons des services de
                    sécurité de qualité pour la protection de vos biens. Nous
                    sommes engagés à fournir des solutions de sécurité
                    personnalisées pour répondre aux besoins spécifiques de nos
                    clients. Contactez-nous dès aujourd'hui pour discuter de vos
                    besoins en matière de sécurité.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-2">
                <ContactUs theme="black" />
              </div>
            </div>
            <div className="col-md-3 offset-md-1 pt-4">
              <h3>Nos prestations :</h3>
              <ServicesListBasic />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};
export default SecuriteBien;
